<template>
  <ion-button fill="solid"
              size="large"
              mode="md"
              color="cto"
              class="gro-button"
              detail
              :disabled="busy"
              v-bind="$attrs">
    <slot />
    <gro-spinner v-if="busy" slot="start" />
  </ion-button>
</template>

<script>
export default {
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang="scss" scoped>
  .gro-button {
    --border-radius: 1px;
    min-height: 48px;
    font-size: 12px;
    font-family: 'Proxima Nova Bold';
  }

  .gro-button.button-block {
      max-width: 400px;
      margin-right: auto;
      margin-left: auto;
   }

  .footer {
    min-height: 64px;
  }

  .plt-ios .footer {
    min-height: 80px;
    --padding-bottom: 17px;
  }
</style>
